import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledContactFormContainer = styled.div`
  position: relative;
  margin: 0 auto;

  ${mq.large`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${mq.xsLarge`
    max-width: ${({ theme }) => theme.sizes.layoutWidth}
  `}
`;

export const StyledBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  clip-path: polygon(46% 0, 100% 60px, 100% 100%, calc(50% - 350px) 100%);
  background: ${({ theme }) => theme.gradients.primary};
`;

export const StyledInputColumn = styled.div`
  position: relative;
  ${mq.large`
    padding-top: ${({ theme }) => theme.spacing(9)};
    max-width: calc(${({ theme }) => theme.sizes.layoutWidth} / 2);
  `}
`;

export const StyledTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq.large`
    margin-bottom: 0;
    padding: ${({ theme }) => theme.spacing(9, 0, 0)};
    justify-self: flex-start;
    margin-right: ${({ theme }) => theme.spacing(4)};
    max-width: 384px;
  `}
`;

export const StyledContactForm = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(14, 0, 5)};
  background: ${({ theme }) => theme.gradients.primaryReverse};

  textarea {
    height: 156px;
    padding-top: 16px;
    overflow-y: auto;
    resize: none;
  }

  ${mq.large`
    background: none;
    padding: 0;
  `}
`;

export const StyledTextWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${mq.large`
    margin-top: ${({ theme }) => theme.spacing(4)};
    max-width: 250px;
  `}
`;

export const StyledContainer = styled.div`
  z-index: 0;

  ${mq.large`
    position: relative;
    padding-top: ${({ theme }) => theme.spacing(6)};
  `}
`;

export const StyledFormWrapper = styled.div`
  position: relative;
  margin: 0 -${({ theme }) => theme.sizes.layoutPadding};
  padding: ${({ theme }) => theme.spacing(3, 0.5, 0)};
  z-index: 0;

  ${mq.large`
    margin: 0;
    padding: 0 0 ${({ theme }) => theme.spacing(3)};
  `}
`;

export const StyledTopSpacingWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(9)};

  ${mq.large`
    padding-top: 0;
  `}
`;

export const StyledFrontendNewsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(6)};

  ${mq.large`
    padding-top: ${({ theme }) => theme.spacing(10)};
  `}
`;
