import React, { FC } from 'react';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Typography from 'components/atoms/Typography/Typography';

import {
  StyledServicesContainer,
  StyledDescriptionWrapper,
  StyledTextWrapper,
  StyledButtonWrapper,
  StyledTitle
} from './ServicesBox.styles';

export type ServicesBoxTitleProps = {
  title: string;
  text: string;
  href: string;
  linkText: string;
};

const ServicesBox: FC<ServicesBoxTitleProps> = ({ title, text, href, linkText }: ServicesBoxTitleProps) => (
  <StyledServicesContainer>
    <StyledTextWrapper>
      <StyledTitle variant="h4" renderAs="h2" weight="700">
        {title}
      </StyledTitle>
      <StyledDescriptionWrapper>
        <Typography variant="caption">{text}</Typography>
      </StyledDescriptionWrapper>
      <StyledButtonWrapper>
        <LinkedButton
          variant="tertiary-white"
          width="188px"
          height="40px"
          justifyContent="flex-end"
          overflow="visible"
          href={href}
        >
          {linkText}
        </LinkedButton>
      </StyledButtonWrapper>
    </StyledTextWrapper>
  </StyledServicesContainer>
);

export default ServicesBox;
