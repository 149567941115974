import styled from 'styled-components';

import Typography from 'components/atoms/Typography/Typography';

export const StyledServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey_25};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 2;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(6, 3)};

  & h2 {
    line-height: 36px;
  }
`;

export const StyledTitle = styled(Typography)`
  hyphens: auto;
  word-break: break-word;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledDescriptionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  flex: 1;
`;
