import React, { FC } from 'react';
import Image from '@next/image';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';

import useMedia from 'hooks/useMedia';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';

import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import {
  StyledTrustedByRow,
  StyledClientsSection,
  StyledTitleWrapper,
  StyledClientsGrid,
  StyledContainer,
  StyledButtonWrapper
} from './TrustedBy.styles';

export type TrustedByProps = {
  title: string;
  companies: Array<UploadedImg>;
  button: { buttonCopy: string; href: string };
  hasHexagon: boolean;
  hasMobileHexagon?: boolean;
  sectionPadding?: string;
};

export const TrustedBy: FC<TrustedByProps> = ({
  title,
  companies,
  button,
  hasHexagon = true,
  sectionPadding,
  hasMobileHexagon = false
}) => {
  const { isMobile, isSmallMobile } = useMedia();

  return (
    <StyledContainer sectionPadding={sectionPadding}>
      <Container>
        {hasHexagon && (
          <Hexagon
            rotate="-15deg"
            width={isMobile ? '522px' : '700px'}
            top={isMobile ? '-40px' : '-170px'}
            right={isMobile ? '-260px' : '-600px'}
            variant="backgroundLight"
            zIndex="-1"
          />
        )}
        {isMobile && hasMobileHexagon && (
          <Hexagon rotate="-15deg" width="522px" top="-40px" right="-260px" variant="backgroundLight" zIndex="-1" />
        )}
        <StyledTrustedByRow>
          <StyledClientsSection>
            <StyledTitleWrapper>
              <Typography variant="h2" renderAs="h5" weight="700">
                {title}
              </Typography>
            </StyledTitleWrapper>
            <StyledClientsGrid>
              {companies.map(({ id, alt, file }) => (
                <Image
                  height={isMobile ? '72px' : '88px'}
                  width={isMobile ? '144px' : '165px'}
                  key={id}
                  src={fromCMS(file.url)}
                  alt={alt}
                  objectFit="contain"
                  objectPosition="left"
                  quality={100}
                  unoptimized
                />
              ))}
            </StyledClientsGrid>
          </StyledClientsSection>
          <StyledButtonWrapper>
            <LinkedButton href={button.href} width={isSmallMobile ? '100%' : undefined}>
              {button.buttonCopy}
            </LinkedButton>
          </StyledButtonWrapper>
        </StyledTrustedByRow>
      </Container>
    </StyledContainer>
  );
};
